import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Container, Button } from 'Common'
import dev from 'Static/illustrations/skills.svg'
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles'

export const Skills = () => (
	<Wrapper id="about">
		<SkillsWrapper as={Container}>
			<Thumbnail>
				<img src={dev} alt="I’m John and I’m a Backend & Devops engineer!" />
			</Thumbnail>
			<Details>
				<h1>Hi There!</h1>
				<p>
					I'm a software engineer with experience in the telecommunications,
					e-commerce and finance industries. I have helped develop scalable and
					highly resilient notification systems as well as APIs that extended
					core banking applications to help ease the process of 3rd party
					integrations.
				</p>
				<p>
					Also, I have built hybrid mobile application prototypes that applied
					natural language processing to help power a conversational style
					retail bot that assisted its users in purchasing items from across 809
					online retail stores.
				</p>
				<Button as={AnchorLink} href="#contact">
					Let's talk
				</Button>
			</Details>
		</SkillsWrapper>
	</Wrapper>
)
